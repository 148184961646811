import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const data = [
  {
    title: "Dataset Explorer",
    description:
      "A tool to explore and filter 1800+ text finetuning datasets for their licenses, sources, creators, and more.",
    link: "/data-provenance-explorer/dataset-explorer",
    background: "linear-gradient(145deg, #A1DDFF, #B2E5FF)",
  },
  {
    title: "Web Data Explorer",
    description:
      "An explorer of the trends in data-use preferences from the websites commonly used to source AI data.",
    link: "/data-provenance-explorer/web-data-explorer",
    background: "linear-gradient(145deg, #FAE4D4, #FCD3C3)",
  },
];

const DataProvenanceExplorer = () => {
  return (
    <Box sx={{ mx: "auto", my: "20px", px: { xs: "10px", md: "0" } }}>
      <Stack
        alignItems="center"
        sx={{
          textAlign: "center",
          mb: { xs: "30px", md: "50px" },
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "40px", xs: "24px" },
            fontWeight: 700,
            maxWidth: "100%",
            lineHeight: { md: "116.18px", xs: "30px" },
          }}
        >
          Data Provenance Explorer
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "18px", xs: "14px" },
            fontWeight: 400,
            maxWidth: "100%",
            color: "#555",
          }}
        >
          Discover the datasets featured in our audit! The DPI Explorer tool
          enables you to filter and analyze language model training datasets
          effortlessly.
          <br />
          <br />
          On the left, explore tools for popular AI datasets, and on the right,
          uncover the common websites from which these datasets are sourced.
        </Typography>
      </Stack>
      <Stack flexDirection={{ xs: "column", md: "row" }} sx={{ gap: { xs: "30px", md: "40px" }, alignItems: "center" }}>
        {data.map((d) => (
          <Box
            key={d.title}
            sx={{
              width: { xl: "627px", sm: "400px", xs: "100%" }, // Use 100% width on small screens
              maxWidth: "400px", // Limit max width for larger screens
              height: { xl: "766px", sm: "auto" }, // Auto height for smaller screens
              aspectRatio: "0.95",
              borderRadius: "15px",
              mx: "auto",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0 8px 30px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Link
              to={d.link}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "100%",
                borderRadius: "15px",
                background: d.background,
                padding: "30px 15px", // Reduced padding for better fit
                color: "black",
                textDecoration: "none",
                transition: "background 0.3s ease",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: { xs: "18px", sm: "22px" }, textAlign: "center" }}>
                {d.title}
              </Typography>
              <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={{ fontWeight: 400, fontSize: { xs: "14px", sm: "16px" }, textAlign: "center" }}>
                  {d.description}
                </Typography>
              </Box>
            </Link>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default DataProvenanceExplorer;
